.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-weight: var(--font-weight);
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  scroll-behavior: smooth;
  transition: color 1000ms ease-in-out, background-color 500ms ease-out;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
