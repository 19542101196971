:root {
  --bg-color: #fff;
  --text-color: #000;
  --accent-color: royalblue;
  --light-color: #bbb;
  --dark-color: #333;
  --purplish: rgb(85, 23, 242);
  --font-weight: 400;
  --light-violet: #b09db9;
}

[theme="dark"] {
  --bg-color: #000;
  --text-color: #fff;
  --accent-color: #188;
  --font-weight: 300;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 18px;
  font-weight: var(--font-weight);
  color: var(--text-color);
  background-color: var(--bg-color);
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 3rem;
  font-weight: 400;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
